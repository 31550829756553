import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Button, Form as BootstrapForm } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  fetchOptionDetailAction,
  fetchUserDetailAction,
} from "../action/newUser/newUserDetailAction";
import { multipleShifts } from "../util/checkSeatAvaFunction";
import { generateSeatNames } from "../util/checkSeatAvaFunction";
import { fetchAllSubscriptionAction } from "../action/newSubscription/newSubscriptionDetailAction";
import {
  handleImageCompress,
  transformShiftsOptions,
  qualificationOptions,
} from "../util/utils";
import { addMemberDetailAction } from "../action/newMember/newMemberDetailAction";
import { auth, uploadImage } from "../firebase/firebase.auth.config";
import TermsModalExample from "./ruleModalComponent";
import { libraryPreDefinedTerms } from "../util/utils";

const AddStudentQr = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { library_name, user_uid } = useParams();
  const decodedLibraryName = decodeURIComponent(library_name);

  const authUser = auth.currentUser;

  // States
  const [sameAsMobile, setSameAsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [seatOptions, setSeatOptions] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [aadharPreview, setAadharPreview] = useState(null);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [otherGoal, setOtherGoal] = useState("");
  const [selectedGoal, setSelectedGoal] = useState("");
  const [otherQualificaton, setOtherQualificaton] = useState("");
  const [selectedQualificaton, setSelectedQualificaton] = useState();

  // Selectors
  const newUserDetail = useSelector((state) => state.newUserDetail);
  // const formFields = useSelector((state) => state.newUserDetail.formFields);
  let formFields = newUserDetail?.data?.formFields;
  const newOption =
    useSelector((state) => state.newUserDetail.optionData) || [];
  const newSubsDetail = useSelector((state) => state.newSubscriptionDetail);
  const terms = useSelector((state) => state.newUserDetail.data.libraryTerms);

  const seats = generateSeatNames(newUserDetail?.data?.library?.floors);
  const shiftFees = newOption[newOption?.length - 1];

  // Effect to update seat options when dates or shifts change
  useEffect(() => {
    if (startDate && endDate && selectedShifts.length > 0) {
      const allotseats = multipleShifts(
        newUserDetail?.data?.library,
        newUserDetail?.data?.members,
        seats,
        startDate,
        endDate,
        selectedShifts,
        newSubsDetail?.data
      );

      const newAllotSeat = allotseats?.map(
        ({ seatNo: label, seatNo: value, ...rest }) => ({
          label,
          value,
          ...rest,
        })
      );

      setSeatOptions(newAllotSeat);

      // Clear selected seat if it's no longer valid
      if (
        selectedSeat &&
        !newAllotSeat.some((option) => option.value === selectedSeat)
      ) {
        setSelectedSeat(null);
      }
    } else {
      setSeatOptions([]);
      setSelectedSeat(null);
    }
  }, [
    startDate,
    endDate,
    selectedShifts,
    newUserDetail?.data,
    // seats,
    newSubsDetail?.data,
  ]);

  // Initial data fetching
  useEffect(() => {
    dispatch(fetchUserDetailAction(user_uid, true));
    dispatch(fetchOptionDetailAction(user_uid, true));
    dispatch(fetchAllSubscriptionAction(user_uid, true));
  }, [dispatch, user_uid]);

  // dropdown option for react select
  const getOptions = (category) =>
    (
      (Array.isArray(newOption) &&
        newOption.find((item) => item.category === category)?.options) ||
      []
    )?.map((option) => ({
      label: option,
      value: option,
    }));

  const shiftOptions = transformShiftsOptions(
    newUserDetail?.data?.library?.shifts
  );
  const goalOptions = getOptions("Goals");

  // checbox
  const handleCheckboxChange = () => {
    setAcceptedTerms(!acceptedTerms);
  };

  // custom style for react-select dropdown
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "48px",
      height: "48px",
      borderRadius: "8px",
      borderColor: "#ced4da",
      boxShadow: "none",
      padding: "0px 8px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "48px",
    }),
  };

  const animatedComponents = makeAnimated();

  // formik initial values
  const initialValues = {
    name: "",
    mobile: "",
    whatsapp: "",
    email: "",
    gender: "",
    dateOfBirth: null,
    fatherName: "",
    qualification: "",
    emergencyContact: "",
    profileImage: null,
    aadharCardDoc: null,
    subscription: [
      {
        seatNo: "",
        shift: "",
        startDate: null,
        endDate: null,
        fee: "",
      },
    ],
    address: "",
    goal: "",
    approved: false,
    aadhar_number: "",
    paymentMethod: "",
    remarks: "",
  };

  // yup validation
  const validationSchema = Yup.object({
    name:
      formFields?.name?.required && Yup.string().required("Name is required"),
    mobile:
      formFields?.contactNum?.required &&
      Yup.string()
        .matches(/^\d+$/, "Mobile number must be numeric")
        .min(10, "Mobile number must be exactly 10 digits")
        .max(10, "Mobile number must be exactly 10 digits")
        .required("Mobile number is required"),
    whatsapp:
      formFields?.whatsappNum?.required &&
      Yup.string()
        .matches(/^\d+$/, "Whatsapp number must be numeric")
        .min(10, "Whatsapp number must be exactly 10 digits")
        .max(10, "Whatsapp number must be exactly 10 digits")
        .required("Whatsapp number is required"),
    aadhar_number:
      formFields?.aadharNumber?.required &&
      Yup.string().required("Aadhar Number is required"),
    email:
      formFields?.email?.required &&
      Yup.string().email("Invalid email address").required("Email is required"),
    gender:
      formFields?.gender?.required &&
      Yup.string().required("Gender is required"),
    subscription: Yup.array().of(
      Yup.object().shape({
        seatNo: Yup.string().required("Seat number is required"),
        shift: Yup.array()
          .required("Shift is required")
          .min(1, "At least one shift is required"),
        startDate: Yup.date()
          .nullable()
          .required("Start date is required")
          .min(
            new Date(new Date().setDate(new Date().getDate() - 61)),
            "Start date cannot be before 60 days ago"
          ),
        endDate: Yup.date()
          .nullable()
          .required("End date is required")
          .min(Yup.ref("startDate"), "End date must be later than start date"),
        fee: Yup.number().required("Fee is required"),
      })
    ),
    address:
      formFields?.address?.required &&
      Yup.string().required("Address is required"),
    feesStatus:
      authUser?.uid === "vUY50mFO54YZWXRQWEsBwL9vDTa2" &&
      Yup.string()
        .oneOf(
          ["fees not refundable"],
          `Input must be exactly "fees not refundable".`
        )
        .required("This is required"),
    goal: formFields?.goal?.required && Yup.string(),
    dateOfBirth:
      formFields?.dateOfBirth?.required &&
      Yup.date()
        .required("Date of birth is required")
        .max(new Date(), "Date of birth cannot be in the future"),
    fatherName:
      formFields?.fatherName?.required &&
      Yup.string().required("Father's name is required"),
    qualification:
      formFields?.qualification?.required &&
      Yup.string().required("Qualification is required"),
    emergencyContact:
      formFields?.emergencyContact?.required &&
      Yup.string()
        .matches(/^\d+$/, "Emergency contact must be numeric")
        .length(10, "Emergency contact must be exactly 10 digits")
        .required("Emergency contact is required"),
    profileImage:
      formFields?.profielPic?.required &&
      Yup.mixed().required("Profile image is required"),
    aadharCardDoc:
      formFields?.aadharImage?.required &&
      Yup.mixed().required("Aadhar card PDF is required"),
    paymentMethod:
      formFields?.paymentMethod?.required &&
      Yup.string().required("Payment Mode is required"),
    remarks:
      formFields?.remarks?.required &&
      Yup.string().required("Remarks are required"),
  });

  const formatDateToISO = (date) => {
    return date ? new Date(date).toISOString() : "";
  };

  const successCb = (data) => {
    toast.success("Submitted successfully!");
    const encodedLibraryName = encodeURIComponent(library_name);
    const encodedDataName = encodeURIComponent(data.name);
    setLoading(false);
    navigate(
      `/student-form-submitted/${encodedLibraryName}/${encodedDataName}`
    );
  };

  const errorCb = (error) => {
    toast.error("Failed to submit the form");
  };

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      let profileImageUrl = "";
      let aadharDocUrl = "";
      if (values?.profileImage != null || values?.profileImage != undefined) {
        profileImageUrl = await uploadImage(values.profileImage).catch(
          (err) => {
            console.error("Profile image upload failed:", err);
            return null; // Or a placeholder URL
          }
        );
      }

      if (values?.aadharCardDoc != null || values?.aadharCardDoc != undefined) {
        aadharDocUrl = await uploadImage(
          values.aadharCardDoc,
          "aadhar_docs"
        ).catch((err) => {
          console.error("Aadhar document upload failed:", err);
          return null; // Or a placeholder URL
        });
      }

      // Format subscription dates if subscription exists
      const formattedValues = {
        ...values,
        subscription: values.subscription?.map((sub) => ({
          ...sub,
          startDate: sub.startDate ? formatDateToISO(sub.startDate) : null,
          endDate: sub.endDate ? formatDateToISO(sub.endDate) : null,
        })),
        dateOfBirth: values.dateOfBirth
          ? formatDateToISO(values.dateOfBirth)
          : null,
        profileImage: profileImageUrl,
        aadharCardDoc: aadharDocUrl,
      };

      // Dispatch action
      dispatch(
        addMemberDetailAction(formattedValues, successCb, errorCb, user_uid)
      );
    } catch (error) {
      console.error("Form submission error:", error);
      errorCb?.(error);
      setLoading(false);
    }
  };

  const NumericField = ({ field, form, ...props }) => (
    <input
      {...field}
      {...props}
      onChange={(e) => {
        const value = e.target.value?.replace(/[^0-9]/g, "");
        form.setFieldValue(field.name, value);
      }}
    />
  );

  return (
    <div className="container my-md-5 my-4">
      <h1 className="text-center heading_three mb-md-3 mb-2 text_gray">
        {decodedLibraryName}
      </h1>
      <h3 className="text-center heading_four mb-4 text_lightGray">
        Fill your details
      </h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <Form className="d-flex flex-column gap-3">
            {/* Previous form fields remain the same until subscription dates */}
            {formFields?.name?.display && (
              <BootstrapForm.Group>
                <BootstrapForm.Label className="label_text">
                  Name
                </BootstrapForm.Label>
                <Field
                  name="name"
                  type="text"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter your name"
                />
                {errors.name && touched.name ? (
                  <div className="text-danger">{errors.name}</div>
                ) : null}
              </BootstrapForm.Group>
            )}
            {formFields?.contactNum?.display && (
              <BootstrapForm.Group>
                <BootstrapForm.Label className="label_text">
                  Mobile
                </BootstrapForm.Label>
                <Field
                  name="mobile"
                  type="text"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter your mobile number"
                  maxLength="10"
                  onChange={(e) => {
                    setFieldValue("mobile", e.target.value);
                    if (sameAsMobile) setFieldValue("whatsapp", e.target.value);
                  }}
                />
                {errors.mobile && touched.mobile ? (
                  <div className="text-danger">{errors.mobile}</div>
                ) : null}
              </BootstrapForm.Group>
            )}

            {formFields?.whatsappNum?.display && (
              <BootstrapForm.Group>
                <BootstrapForm.Label className="label_text">
                  WhatsApp
                </BootstrapForm.Label>
                <Field
                  name="whatsapp"
                  type="text"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter your WhatsApp number"
                  maxLength="10"
                />
                <BootstrapForm.Check
                  type="checkbox"
                  label="Same as mobile"
                  onChange={() => {
                    setSameAsMobile(!sameAsMobile);
                    if (!sameAsMobile) {
                      setFieldValue("whatsapp", values.mobile);
                    }
                  }}
                  checked={sameAsMobile}
                />
                {errors.whatsapp && touched.whatsapp ? (
                  <div className="text-danger">{errors.whatsapp}</div>
                ) : null}
              </BootstrapForm.Group>
            )}

            {formFields?.dateOfBirth?.display && (
              <BootstrapForm.Group>
                <BootstrapForm.Label className="label_text">
                  Date of Birth{" "}
                  {!formFields?.dateOfBirth?.required && (
                    <span className="pri_text"> (Optional) </span>
                  )}
                </BootstrapForm.Label>
                <DatePicker
                  selected={values.dateOfBirth}
                  onChange={(date) => setFieldValue("dateOfBirth", date)}
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  placeholderText="Select Date of Birth"
                  className={`form-control input_feild ${
                    errors.dateOfBirth && touched.dateOfBirth
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {errors.dateOfBirth && touched.dateOfBirth && (
                  <div className="text-danger">{errors.dateOfBirth}</div>
                )}
              </BootstrapForm.Group>
            )}

            {formFields?.fatherName?.display && (
              <BootstrapForm.Group className="">
                <BootstrapForm.Label className="label_text">
                  Father's Name{" "}
                  {!formFields?.fatherName?.required && (
                    <span className="pri_text"> (Optional) </span>
                  )}
                </BootstrapForm.Label>
                <Field
                  name="fatherName"
                  type="text"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter father's name"
                />
                {errors.fatherName && touched.fatherName && (
                  <div className="text-danger">{errors.fatherName}</div>
                )}
              </BootstrapForm.Group>
            )}

            {formFields?.address?.display && (
              <BootstrapForm.Group>
                <BootstrapForm.Label className="label_text">
                  Address
                  {!formFields?.address?.required && (
                    <span className="pri_text"> (Optional) </span>
                  )}
                </BootstrapForm.Label>
                <Field
                  name="address"
                  as="textarea"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter your address"
                />
                {errors.address && touched.address ? (
                  <div className="text-danger">{errors.address}</div>
                ) : null}
              </BootstrapForm.Group>
            )}

            {formFields?.qualification?.display && (
              <BootstrapForm.Group>
                <BootstrapForm.Label className="label_text">
                  Qualification{" "}
                  {!formFields?.qualification?.required && (
                    <span className="pri_text"> (Optional) </span>
                  )}
                </BootstrapForm.Label>
                <Select
                  options={qualificationOptions}
                  className="formBodyControlModal"
                  components={animatedComponents}
                  placeholder="Select Qualification"
                  onChange={(option) => {
                    setSelectedQualificaton(option.value);
                    if (option.value !== "other") {
                      setOtherQualificaton("");
                      setFieldValue("qualification", option.value);
                    }
                  }}
                  styles={customStyles}
                />
                {errors.qualification && touched.qualification && (
                  <div className="text-danger">{errors.qualification}</div>
                )}
                {selectedQualificaton === "other" && (
                  <div>
                    <BootstrapForm.Label className="label_text">
                      Please Specify
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      placeholder="Enter your qualification"
                      value={otherQualificaton}
                      onChange={(e) => {
                        const value = e.target.value;
                        setOtherQualificaton(value);
                        setFieldValue("qualification", value);
                      }}
                    />
                  </div>
                )}
              </BootstrapForm.Group>
            )}

            {formFields?.emergencyContact?.display && (
              <BootstrapForm.Group className="">
                <BootstrapForm.Label className="label_text">
                  Emergency Contact{" "}
                  {!formFields?.emergencyContact?.required && (
                    <span className="pri_text"> (Optional) </span>
                  )}
                </BootstrapForm.Label>
                <Field
                  name="emergencyContact"
                  component={NumericField}
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter emergency contact number"
                  maxLength="10"
                />
                {errors.emergencyContact && touched.emergencyContact && (
                  <div className="text-danger">{errors.emergencyContact}</div>
                )}
              </BootstrapForm.Group>
            )}

            {formFields?.profielPic?.display && (
              <BootstrapForm.Group className="">
                <BootstrapForm.Label className="label_text">
                  Profile Image{" "}
                  {!formFields?.profielPic?.required && (
                    <span className="pri_text"> (Optional) </span>
                  )}
                </BootstrapForm.Label>
                <input
                  type="file"
                  accept="image/*"
                  capture="environment"
                  onChange={async (event) => {
                    const file = event.currentTarget.files[0];
                    if (file) {
                      const compressedFile = await handleImageCompress(
                        file,
                        setImagePreview
                      );
                      setFieldValue("profileImage", compressedFile);
                    }
                  }}
                  className="form-control"
                />
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="mt-2"
                    style={{ maxWidth: "200px" }}
                  />
                )}
                {errors.profileImage && touched.profileImage && (
                  <div className="text-danger">{errors.profileImage}</div>
                )}
              </BootstrapForm.Group>
            )}

            {formFields?.aadharNumber?.display && (
              <BootstrapForm.Group className=" px-0">
                <BootstrapForm.Label className="label_text">
                  Aadhar number{" "}
                  {!formFields?.aadharNumber?.required && (
                    <span className="pri_text"> (Optional) </span>
                  )}
                </BootstrapForm.Label>
                <Field
                  name="aadhar_number"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter aadhar"
                  maxLength="14"
                  onChange={(e) => {
                    const rvalue = e.target.value.replace(/\D/g, "");
                    const formattedValue =
                      rvalue
                        .match(/.{1,4}/g) // Split into groups of 1 to 4 digits
                        ?.join(" ") // Join groups with spaces
                        .trim() || ""; // Trim any trailing spaces
                    setFieldValue("aadhar_number", formattedValue);
                    e.target.value = formattedValue; // Update the input value
                  }}
                />
                {errors.aadhar_number && touched.aadhar_number && (
                  <div className="text-danger">{errors.aadhar_number}</div>
                )}
              </BootstrapForm.Group>
            )}
            {/* Aadhar Card PDF Upload */}
            {formFields?.aadharImage?.display && (
              <BootstrapForm.Group className="">
                <BootstrapForm.Label className="label_text">
                  Aadhar Card Image{" "}
                  {!formFields?.aadharImage?.required && (
                    <span className="pri_text"> (Optional) </span>
                  )}
                </BootstrapForm.Label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={async (event) => {
                    const file = event.currentTarget.files[0];
                    if (file) {
                      const compressedFile = await handleImageCompress(
                        file,
                        setAadharPreview
                      );
                      setFieldValue("aadharCardDoc", compressedFile);
                    }
                  }}
                  className="form-control"
                />
                {errors.aadharCardDoc && touched.aadharCardDoc && (
                  <div className="text-danger">{errors.aadharCardDoc}</div>
                )}
              </BootstrapForm.Group>
            )}
            {formFields?.email?.display && (
              <BootstrapForm.Group>
                <BootstrapForm.Label className="label_text">
                  Email{" "}
                  {!formFields?.email?.required && (
                    <span className="pri_text"> (Optional) </span>
                  )}
                </BootstrapForm.Label>
                <Field
                  name="email"
                  type="email"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter your email"
                />
                {errors.email && touched.email ? (
                  <div className="text-danger">{errors.email}</div>
                ) : null}
              </BootstrapForm.Group>
            )}

            {formFields?.gender?.display && (
              <BootstrapForm.Group>
                <BootstrapForm.Label className="label_text">
                  Gender
                </BootstrapForm.Label>
                <div className="d-flex gap-4">
                  <label className="mb-0 d-flex gap-2">
                    <Field type="radio" name="gender" value="Male" />
                    Male
                  </label>
                  <label className="mb-0 d-flex gap-2">
                    <Field type="radio" name="gender" value="Female" />
                    Female
                  </label>
                </div>
                {errors.gender && touched.gender ? (
                  <div className="text-danger">{errors.gender}</div>
                ) : null}
              </BootstrapForm.Group>
            )}

            {
              <div>
                <BootstrapForm.Label className="label_text w-100">
                  Subscription duration
                </BootstrapForm.Label>
                <div className="d-flex flex-column flex-md-row gap-3">
                  <DatePicker
                    selected={values.subscription[0].startDate}
                    onChange={(date) => {
                      setFieldValue("subscription[0].startDate", date);
                      setStartDate(date);
                    }}
                    startDate={values.subscription[0].startDate}
                    endDate={values.subscription[0].endDate}
                    minDate={
                      new Date(new Date().setDate(new Date().getDate() - 60))
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                    className={`form-control input_feild ${
                      errors.subscription?.[0]?.startDate &&
                      touched.subscription?.[0]?.startDate
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <DatePicker
                    selected={values.subscription[0].endDate}
                    onChange={(date) => {
                      setFieldValue("subscription[0].endDate", date);
                      setEndDate(date);
                    }}
                    selectsEnd
                    startDate={values.subscription[0].startDate}
                    endDate={values.subscription[0].endDate}
                    minDate={values.subscription[0].startDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    className={`form-control input_feild ${
                      errors.subscription?.[0]?.endDate &&
                      touched.subscription?.[0]?.endDate
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                </div>
              </div>
            }

            <BootstrapForm.Group>
              <BootstrapForm.Label className="label_text">
                Shift
              </BootstrapForm.Label>
              <Select
                options={shiftOptions}
                className="formBodyControlModal"
                components={animatedComponents}
                placeholder="Select Shift (Multiple Select)"
                isMulti
                onChange={(selectedOptions) => {
                  if (selectedOptions[0]?.shift === "FullDayShift")
                    selectedOptions?.splice(1, selectedOptions?.length);
                  if (
                    selectedOptions[selectedOptions.length - 1]?.shift ===
                    "FullDayShift"
                  )
                    selectedOptions?.splice(0, selectedOptions?.length - 1);

                  const formattedOptions = selectedOptions?.map((option) => {
                    const formattedValue = option.value.split("(")[0].trim();
                    return formattedValue;
                  });

                  setFieldValue("subscription[0].shift", formattedOptions);
                  setSelectedShifts(selectedOptions);
                }}
                styles={customStyles}
              />
              {errors.subscription?.[0]?.shift &&
              touched.subscription?.[0]?.shift ? (
                <div className="text-danger">
                  {errors.subscription[0].shift}
                </div>
              ) : null}
            </BootstrapForm.Group>

            <BootstrapForm.Group>
              <BootstrapForm.Label className="label_text">
                Seat No.
              </BootstrapForm.Label>
              <Select
                options={seatOptions}
                className="formBodyControlModal"
                components={animatedComponents}
                placeholder={
                  !startDate || !endDate || selectedShifts.length === 0
                    ? "Select Shift & dates to show seat options"
                    : "Select Seat No."
                }
                value={
                  seatOptions.find((option) => option.value === selectedSeat) ||
                  null
                }
                isDisabled={
                  !startDate || !endDate || selectedShifts.length === 0
                }
                onChange={(option) => {
                  const seatValue = option ? option.value : "";
                  setSelectedSeat(seatValue);
                  setFieldValue("subscription[0].seatNo", seatValue);
                }}
                styles={customStyles}
              />
            </BootstrapForm.Group>

            {formFields?.fee?.display && (
              <BootstrapForm.Group>
                <BootstrapForm.Label className="label_text">
                  Fee
                </BootstrapForm.Label>
                <Field
                  name="subscription[0].fee"
                  type="number"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter Fee Paid"
                />
                {errors.subscription?.[0]?.fee &&
                touched.subscription?.[0]?.fee ? (
                  <div className="text-danger">
                    {errors.subscription[0].fee}
                  </div>
                ) : null}
              </BootstrapForm.Group>
            )}

            {formFields?.paymentMethod?.display && (
              <BootstrapForm.Group>
                <BootstrapForm.Label className="label_text">
                  Payment Mode{" "}
                  {!formFields?.paymentMethod?.required && (
                    <span className="pri_text"> (Optional) </span>
                  )}
                </BootstrapForm.Label>
                <Field
                  name="paymentMethod"
                  type="text"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Payment Mode"
                />
                {errors?.paymentMethod && touched?.paymentMethod && (
                  <div className="text-danger">{errors.paymentMethod}</div>
                )}
              </BootstrapForm.Group>
            )}

            {formFields?.remarks?.display && (
              <BootstrapForm.Group>
                <BootstrapForm.Label className="label_text">
                  Any Remarks{" "}
                  {!formFields?.remarks?.required && (
                    <span className="pri_text"> (Optional) </span>
                  )}
                </BootstrapForm.Label>
                <Field
                  name="remarks"
                  type="text"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Remarks"
                />
                {errors?.remarks && touched?.remarks && (
                  <div className="text-danger">{errors.remarks}</div>
                )}
              </BootstrapForm.Group>
            )}

            {formFields?.goal?.display && (
              <BootstrapForm.Group>
                <BootstrapForm.Label className="label_text">
                  Goal{" "}
                  {!formFields?.goal?.required && (
                    <span className="pri_text"> (Optional) </span>
                  )}
                </BootstrapForm.Label>
                <Select
                  options={goalOptions}
                  className="formBodyControlModal"
                  components={animatedComponents}
                  placeholder="Select Goal"
                  onChange={(option) => {
                    setSelectedGoal(option.value);
                    if (option.value !== "Others") {
                      setOtherGoal("");
                      setFieldValue("goal", option.value);
                    }
                  }}
                  styles={customStyles}
                />
                {selectedGoal === "Others" && (
                  <div>
                    <BootstrapForm.Label className="label_text">
                      Please Specify
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      placeholder="Enter your goal"
                      value={otherGoal}
                      onChange={(e) => {
                        const value = e.target.value;
                        setOtherGoal(value);
                        setFieldValue("goal", value);
                      }}
                    />
                  </div>
                )}
              </BootstrapForm.Group>
            )}

            {(authUser?.uid === "vUY50mFO54YZWXRQWEsBwL9vDTa2" ||
              authUser?.uid === "UrMJ15yCjFPAKjK1t6IPukca1wr2") && (
              <BootstrapForm.Group>
                <BootstrapForm.Label className="label_text">
                  Please type <b>fees not refundable</b> here
                </BootstrapForm.Label>
                <Field
                  name="feesStatus"
                  as="textarea"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Type here"
                />
                {errors.feesStatus && touched.feesStatus ? (
                  <div className="text-danger">{errors.feesStatus}</div>
                ) : null}
              </BootstrapForm.Group>
            )}

            <div>
              <div className="checkbox-container mt-4 d-flex align-iten-top">
                <input
                  type="checkbox"
                  id="acceptedTerms"
                  checked={acceptedTerms}
                  onChange={handleCheckboxChange}
                  className="me-2"
                />
                <label htmlFor="acceptedTerms">
                  I have read the{" "}
                  <TermsModalExample
                    terms={
                      terms?.["content"] == undefined ||
                      terms?.["content"] == null ||
                      terms?.["content"].trim() == ""
                        ? libraryPreDefinedTerms?.["content"]
                        : terms?.["content"]
                    }
                  />{" "}
                  for the library
                </label>
              </div>

              {/* <div>
                <strong>Accepted Terms: </strong>
                {acceptedTerms ? "Yes" : "No"}
              </div> */}
            </div>

            <div className="d-flex border-0 gap-2 outline-0 justify-content-between my-2">
              <Button
                className="pri_bg button_design w-50 m-auto shadow-none"
                variant="secondary"
                type="submit"
                disabled={!acceptedTerms || loading}
              >
                {loading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddStudentQr;
