import React, { useEffect, useState } from "react"; 
import { format } from "date-fns";
import { encode, decode } from "js-base64";
import { generatePDFReciept } from "../../util/utils";

// Secure data encoding function
export const generateSecureShareLink = (data) => {
  const jsonData = JSON.stringify(data);
  const encodedData = encode(jsonData);
  const checksum = calculateChecksum(encodedData);
  return `${window.location.origin}/r/${checksum}/${encodedData}`;
};

// Decode and validate data
export const extractReceiptData = (encodedPath) => {
  try {
    const pathParts = encodedPath.split("/");
    const checksum = pathParts[pathParts.length - 2];
    const encodedData = pathParts[pathParts.length - 1];

    if (checksum !== calculateChecksum(encodedData)) {
      throw new Error("Invalid data");
    }

    const jsonData = decode(encodedData);
    return JSON.parse(jsonData);
  } catch (error) {
    console.error("Data extraction failed:", error);
    return null;
  }
};

// Simple checksum calculation
const calculateChecksum = (data) => {
  let hash = 0;
  for (let i = 0; i < data.length; i++) {
    const char = data.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return Math.abs(hash).toString(36).slice(0, 6);
};

const ReceiptTempleteComponent = () => {
  const [receiptData, setReceiptData] = useState({
    libraryName: "",
    studentName: "",
    studentId: "",
    duration: "",
    shift: "",
    seatNo: "",
    fee: "",
    startDate: "",
    endDate: "",
    location: "",
    paymentMethod: "",
  });

  useEffect(() => {
    const path = window.location.pathname;
    if (path.startsWith("/r/")) {
      const extractedData = extractReceiptData(path);
      if (extractedData) {
        setReceiptData(extractedData);
      }
    }
  }, []);

 
   const downloadPDF = () => {
    const doc = generatePDFReciept(
      receiptData?.studentName,
      receiptData?.studentId,
      receiptData?.startDate,
      receiptData?.endDate,
      receiptData?.shift,
      receiptData?.seatNo,
      receiptData?.fee,
      receiptData?.paymentMethod,
      receiptData?.location,
      receiptData?.libraryName
    );
    const fileName = `${receiptData?.studentName}_${format(
      new Date(),
      "dd-MM-yyyy"
    )}.pdf`.replace(/[<>:"/\\|?*]+/g, "_");

    // Save the PDF directly
    doc.save(fileName);
  };

  return (
    <div className="custom-receipt-container">
      <div id="custom-receipt" className="custom-receipt">
        <div className="custom-receipt-header">
          <h1>Librify x {receiptData.libraryName}</h1>
          <p>{receiptData.location}</p>
        </div>

        <div className="custom-receipt-content">
          <div className="custom-receipt-section">
            <h2>Student Details</h2>
            <div className="custom-receipt-detail">
              <span>Name:</span>
              <p>{receiptData.studentName}</p>
            </div>
            <div className="custom-receipt-detail">
              <span>Student ID:</span>
              <p>{receiptData.studentId}</p>
            </div>
          </div>

          <div className="custom-receipt-section">
            <h2>Subscription Details</h2>
            <div className="custom-receipt-detail">
              <span>Start Date:</span>
              <p>{receiptData.startDate}</p>
            </div>
            <div className="custom-receipt-detail">
              <span>End Date:</span>
              <p>{receiptData.endDate}</p>
            </div>
            <div className="custom-receipt-detail">
              <span>Shift:</span>
              <p>{receiptData.shift}</p>
            </div>
            <div className="custom-receipt-detail">
              <span>Seat No:</span>
              <p>{receiptData.seatNo}</p>
            </div>
            <div className="custom-receipt-detail">
              <span>Fee:</span>
              <p>₹{receiptData.fee}</p>
            </div>
            <div className="custom-receipt-detail">
              <span>Payment method:</span>
              <p>{receiptData.paymentMethod}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="custom-receipt-footer">
        <button onClick={downloadPDF}>Download PDF</button>
      </div>
    </div>
  );
};

export default ReceiptTempleteComponent;
