import React, { useState, useEffect } from "react";
import ViewAllModal from "./viewAllModal";
import ReNewSubModalComponent from "./reNewSubModalComponent";
import { calculateDuration } from "../../util/utilsFunction";
import { storageRef } from "../../firebase/firebase.auth.config";

const TableComponent = ({ modalTitle, data, newOption, expired = false }) => {
  const [rowData, setRowData] = useState(data);
  const [sortedData, setSortedData] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [rowsPerPage] = useState(3);
  const [showModal, setShowModal] = useState(false);
  const [showModalRenewSub, setShowModalRenewSub] = useState(false);
  const libraryName = localStorage.getItem("library_name");

  // Show only first 3 rows initially
  const paginatedData = sortedData.slice(0, rowsPerPage);

  useEffect(() => {
    setSortedData([...rowData]); // Set sorted data initially
  }, [rowData]);

  const handleMarkAsPaid = (row) => {
    setCurrentRow(row);
    setShowModalRenewSub(true);
    const updatedData = rowData?.map((item) =>
      item.memberID === row.memberID ? { ...item, status: "Paid" } : item
    );
    setRowData(updatedData);
  };

  const handleWhatsAppMessage = async (row) => {
    const endDate = new Date(
      row.subscription?.[row.subscription?.length - 1].endDate
    ).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const shiftName = row.subscription?.[row.subscription?.length - 1].shift;

    const expiredMsg = `Dear *${row.name}*,%0A%0AWe hope this message finds you well. We wanted to kindly remind you that your  *${libraryName}* subscription (${shiftName}, ID ${row.memberID}) has been expired on *${endDate}*. To ensure uninterrupted access, please renew your subscription.%0A%0AThank you for being a valued member of our community.%0A%0AWarm regards,%0A%0A *${libraryName}*`;
    const message = `Dear *${row.name}*,%0A%0AWe hope this message finds you well. We wanted to kindly remind you that your  *${libraryName}* subscription (${shiftName}, ID ${row.memberID}) will expire on *${endDate}*. To ensure uninterrupted access, please renew your subscription before the expiration date.%0A%0AThank you for being a valued member of our community.%0A%0AWarm regards,%0A%0A *${libraryName}*`;
    const whatsappUrl = `https://wa.me/+91${row.whatsapp}?text=${
      expired ? expiredMsg : message
    }`;

    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="container mt-4 p-0">
      <div className="table-responsive overflow-x-scroll">
        {data?.length > 0 ? (
          <table className="table custom-table-dashboard">
            <thead>
              <tr>
                <th className="header-cell pri_border_bottom text-nowrap">
                  Student ID
                </th>
                <th className="header-cell pri_border_bottom text-nowrap">
                  Name
                </th>
                <th className="header-cell pri_border_bottom text-nowrap">
                  Number
                </th>
                <th className="header-cell pri_border_bottom text-nowrap">
                  Fee Duration
                </th>
                <th className="header-cell pri_border_bottom text-nowrap">
                  Seat No.
                </th>
                <th className="header-cell pri_border_bottom text-nowrap">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((row) => (
                <tr key={row.memberID}>
                  <td className="table_row_cell text-nowrap">{row.memberID}</td>
                  <td className="table_row_cell text-nowrap">{row.name}</td>
                  <td className="table_row_cell text-nowrap">{row.mobile}</td>
                  <td className="table_row_cell text-nowrap">
                    {calculateDuration(
                      row.subscription?.[row.subscription?.length - 1]
                        .startDate,
                      row.subscription?.[row.subscription?.length - 1].endDate
                    )}
                  </td>

                  <td className="table_row_cell text-nowrap">
                    {row.subscription?.[row.subscription?.length - 1].seatNo}
                  </td>
                  <td className="d-flex gap-1 table_row_cell text-nowrap">
                    <button
                      className="border-0 bg-transparent d-flex align-items-center gap-1"
                      onClick={() => handleMarkAsPaid(row)}
                    >
                      <span
                        className={`material-symbols-outlined icon_dimention`}
                      >
                        check_circle
                      </span>
                      <p className="table_row_cell text-nowrap mb-0">Paid</p>
                    </button>

                    <button
                      className="border-0 bg-transparent d-flex align-items-center gap-1"
                      onClick={() => handleWhatsAppMessage(row)}
                    >
                      <span className="icon_dimention">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                        </svg>
                      </span>
                      <p className="table_row_cell text-nowrap mb-0">Message</p>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="w-100 text-center text-nowrap">No Data Available</p>
        )}
      </div>
      {data?.length > 3 && (
        <div className="d-flex align-items-center justify-content-end">
          <button
            className={`button_design view_all_btn ${
              data?.length <= 3 ? "disabled" : ""
            }`}
            onClick={() => setShowModal(true)}
          >
            View All
          </button>
        </div>
      )}
      <ViewAllModal
        show={showModal}
        onClose={() => setShowModal(false)}
        rowData={sortedData}
        handleMarkAsPaid={handleMarkAsPaid}
        modalTitle={modalTitle}
        newOption={newOption}
      />
      {currentRow && (
        <ReNewSubModalComponent
          data={currentRow}
          setShowModalRenewSub={setShowModalRenewSub}
          show={showModalRenewSub}
          newOption={newOption}
        />
      )}
    </div>
  );
};

export default TableComponent;
