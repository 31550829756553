import React from "react";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { generateSecureShareLink } from "./ReceiptTempleteComponent";
import { generatePDFReciept } from "../../util/utils";

const shortenURL = async (longURL) => {
  try {
    const response = await fetch(
      `https://tinyurl.com/api-create.php?url=${encodeURIComponent(longURL)}`
    );
    if (!response.ok) throw new Error("URL shortening failed");
    return await response.text();
  } catch (error) {
    console.error("URL shortening failed:", error);
    return longURL;
  }
};

const createWhatsAppMessage = (student, subscription, shareLink) => {
  const libraryName = localStorage.getItem("library_name") || "Library";

  return `Dear ${student.name},

Thank you for joining ${libraryName}.

Receipt Details:
Name: ${student.name}
Member ID: ${student.memberID}
Shift: ${subscription.shift.join(", ")}
Start Date: ${format(new Date(subscription.startDate), "dd/MM/yyyy")}
End Date: ${format(new Date(subscription.endDate), "dd/MM/yyyy")}
Seat No: ${subscription.seatNo}
Fee: ₹${subscription.fee}/-

View Receipt: ${shareLink}

Best Regards,
${libraryName} Team`;
};

const handleShare = async (student, subscription, libraryDetails) => {
  const shareData = {
    libraryName: localStorage.getItem("library_name") || "Library",
    studentName: student.name,
    studentId: student.memberID,
    duration: `${format(
      new Date(subscription.startDate),
      "dd/MM/yyyy"
    )} to ${format(new Date(subscription.endDate), "dd/MM/yyyy")}`,
    startDate: format(new Date(subscription.startDate), "dd/MM/yyyy"),
    endDate: format(new Date(subscription.endDate), "dd/MM/yyyy"),
    shift: subscription.shift.join(", "),
    seatNo: subscription.seatNo,
    fee: subscription.fee,
    location:
      libraryDetails.libraryLocation + ", " + libraryDetails.libraryContact,
    paymentMethod: student.paymentMethod,
  };

  const secureShareLink = generateSecureShareLink(shareData);
  const shortLink = await shortenURL(secureShareLink);

  const message = createWhatsAppMessage(student, subscription, shortLink);
  const waNumber = student.whatsapp?.replace(/\D/g, "") || "";

  if (!waNumber) {
    toast.error("WhatsApp number not found");
    return;
  }

  window.open(
    `https://wa.me/+91${waNumber}?text=${encodeURIComponent(message)}`,
    "_blank"
  );
  toast.success("Opening WhatsApp...");
};

const handleDownload = async (student, subscription, libraryDetails) => {
  try {
    const doc = generatePDFReciept(
      student.name,
      student.memberID,
      subscription.startDate,
      subscription.endDate,
      subscription.shift.join(", "),
      subscription.seatNo,
      subscription.fee,
      student.paymentMethod,
      libraryDetails.libraryLocation + ", " + libraryDetails.libraryContact,
      localStorage.getItem("library_name") || "Library"
    );
    const fileName = `${student.name}_${format(
      new Date(),
      "dd-MM-yyyy"
    )}.pdf`.replace(/[<>:"/\\|?*]+/g, "_");

    // Save the PDF directly
    doc.save(fileName);

    toast.success("Receipt downloaded successfully!");
  } catch (error) {
    console.error("Error:", error);
    toast.error("Failed to download receipt");
  }
};

const ReceiptButtons = ({ student, subscription, libraryDetails }) => (
  <div className="d-flex gap-2 mt-2">
    <button
      className="pri_radious border-none select_duration_btn d-flex align-items-center gap-2 px-3"
      onClick={() => handleShare(student, subscription, libraryDetails)}
    >
      <span className="material-symbols-outlined">share</span>
      Share
    </button>
    <button
      className="pri_radious border-none select_duration_btn d-flex align-items-center gap-2 px-3"
      onClick={() => handleDownload(student, subscription, libraryDetails)}
    >
      <span className="material-symbols-outlined">download</span>
      Download
    </button>
  </div>
);

export default ReceiptButtons;
